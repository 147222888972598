import React, {useEffect, useState} from 'react'
import logo from './logo.svg'
import './App.css'
import TestForm, {
	InputContainer,
	Option,
	Select,
	InputLabel,
	Root,
	Title,
	TitleWrapper
} from './components/Others/TestForm'
// import {ProtectionChangeEventArgs, RefundableOptions} from './types'
// import RefundableContent, { RefundableProvider} from './components/RefundableContent'
import {
	RefundableOptions,
	RefundableProvider,
	ProtectionChangeEventArgs,
	RefundableContent,
	RefundableContentProps
} from 'protect-group-refundable-react'

const defaultOptions: RefundableOptions = {
	environment: process.env.REACT_APP_ENVIRONMENT === 'test' ? 'test' : 'test',
	currencyCode: 'GBP',
	languageCode: 'en',
	offeringMethod: 'SELECT',
	productCode: 'PKG',
	vendorCode: 'ven_local_855f35b4fb9849ad9c3e75fc8a1cb804',
	eventDateFormat: 'DD/MM/YYYY',
	premium: 10,
	whiteLabelled: false,
	displayTrustPilot: true,
	displayRefundText: true,
	allowNonRefundableLabelClick: false,
	labelText: 'Upgrade',
	vertical: 'Default',
	compact: false
}

function App() {
	const [stylePath, setStylePath] = useState('')
	const [changeEventArgs, setChangeEventArgs] = useState<ProtectionChangeEventArgs>({} as ProtectionChangeEventArgs)
	const [refundableOptions, setRefundableOptions] = useState<RefundableOptions>(defaultOptions)

	useEffect(() => {
		if (!stylePath) {
			return
		}

		const head = document.head
		const link = document.createElement('link')

		link.type = 'text/css'
		link.rel = 'stylesheet'
		link.href = stylePath

		head.appendChild(link)

		return () => {
			head.removeChild(link)
		}
	}, [stylePath])

	const handleProtectionChange = (args: ProtectionChangeEventArgs): void => {
		console.log('change args', args)
		setChangeEventArgs(args)
	}

	const handleOptionsChange = (options: RefundableOptions) => setRefundableOptions(options)

	const handleStyleChange = (event: any) => setStylePath(event.target.value)

	return (
		<div className="App">
			<Root>
				<TitleWrapper>
					<Title>Protect Group Refundable React Component Demo</Title>
				</TitleWrapper>
				<RefundableProvider options={refundableOptions}>
					<div style={{padding: 20}}>
						<InputContainer>
							<InputLabel>Examples of overridden styles when the component is white labelled</InputLabel>
							<Select value={stylePath} onChange={handleStyleChange}>
								<Option value="">Default</Option>
								<Option value="Stayflexi.css">Example 1</Option>
								<Option value="AtgTickets.css">Example 2</Option>
							</Select>
						</InputContainer>
					</div>
					<div style={{paddingLeft: 20}}>
						<RefundableContent bookingCost={100} onProtectionChange={handleProtectionChange}/>
					</div>
					<div style={{padding: 20, width: '100%'}}>
						<TestForm
							options={refundableOptions}
							changeEventArgs={changeEventArgs}
							onOptionsChange={handleOptionsChange}
						/>
					</div>
				</RefundableProvider>
			</Root>
		</div>
	)
}

export default App
