// import {
// 	ProtectionChangeEventArgs,
// 	RefundableOptions,
// 	WriteTransactionRequest,
// 	WriteTransactionResponse
// } from '../../types'
// import {useRefundableActions} from '../../hooks'
// import {useRefundableContext} from '../RefundableContent'
import styled from 'styled-components'
import {useState} from 'react'
import {v4 as uuid} from 'uuid'
import moment from 'moment'
import InnerHtml from '../RefundableContent/StyledContent/InnerHtml'
import {
	useRefundableContext,
	useRefundableActions,
	RefundableOptions,
	WriteTransactionRequest,
	WriteTransactionResponse,
	ProtectionChangeEventArgs
} from 'protect-group-refundable-react'

export const Root = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`

const InputsWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex: 100%;
  }
`

const EventArgsWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex: 100%;
  }
`

const EventArg = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 2;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex: 100%;
  justify-content: center;
  background-color: black;
`

export const Title = styled.h1`
  color: white;
`

const SubTitle = styled.h4``

interface InputProps {
	cursor?: string
	isCheckbox?: boolean
}

export const InputContainer = styled.div<InputProps>`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 10px;
  padding-bottom: ${props => props.isCheckbox ? '20px' : '10px'};

  :hover {
    cursor: ${props => props.cursor ? props.cursor : 'default'};
  }

  @media (max-width: 768px) {
    flex-direction: ${props => props.isCheckbox !== true ? 'column' : 'row'};
  }
`

export const InputLabel = styled.label<InputProps>`
  display: inline-flex;
  flex-grow: 1;

  :hover {
    cursor: ${props => props.cursor ? props.cursor : 'default'};
  }

  @media (max-width: 768px) {
    padding-bottom: 5px;
  }
`

const Input = styled.input.attrs(props => ({
	type: props.type ?? 'text'
}))`
  border-radius: 5px;
  border: 1px solid #B4B0B0;
  padding: 8px;
  width: ${props => props.type !== 'checkbox' ? '174px' : 'auto'};

  :focus {
    border-color: #5E5DF0;
  }

  :focus-visible {
    border-color: #5E5DF0;
    outline: none;
  }

  :hover {
    border-color: #605ff5;
  }

  @media (max-width: 768px) {
    width: initial;
  }
`

const InputHint = styled.div`
  display: flex;
	flex-grow: 1;
  flex-direction: column;
	padding-bottom: 15px;
	width: 100%;
`

const Hint = styled.p`
	display: inline-flex;
	justify-content: flex-start;
	margin: 0;
	font-size: small;
	font-weight: 350;
	text-align: start;
`

const ButtonWrapper = styled.div`
  padding-top: 10px;

  button {
    margin-right: 20px;
  }
`

const Button = styled.button.attrs(props => ({
	disabled: props.disabled
}))`
  background: ${props => props.disabled ? '#DDDDDD' : '#5E5DF0'};
  border-radius: 999px;
  box-shadow: ${props => props.disabled ? 'none' : '#5E5DF0 0 10px 20px -10px'};
  box-sizing: border-box;
  color: ${props => props.disabled ? '#000000' : '#FFFFFF'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;

  :hover {
	  background: ${props => props.disabled ? '#DDDDDD' : '#605ff5'};
    box-shadow: ${props => props.disabled ? 'none' : '#605ff5 0 10px 20px -10px'};
  }
`

export const Select = styled.select`
  border-radius: 5px;
  border: 1px solid #B4B0B0;
  padding: 8px;
  width: 192px;

  :hover {
    border-color: #605ff5;
  }

  :focus-visible {
    border-color: #5E5DF0;
    outline: none;
  }

  @media (max-width: 768px) {
    width: auto;
  }
`

export const Option = styled.option``

const ResponseWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: start;
`

const ErrorResponse = styled.p`
  color: red;
`

interface TestFormProps {
	options: RefundableOptions
	changeEventArgs: ProtectionChangeEventArgs
	onOptionsChange: (options: RefundableOptions) => void
}

const TestForm = (props: TestFormProps) => {
	const {
		options, changeEventArgs, onOptionsChange
	} = props
	const [apiSuccessResponse, setApiSuccessResponse] = useState<WriteTransactionResponse>({} as WriteTransactionResponse)
	const [apiErrorResponse, setApiErrorResponse] = useState<any>('')
	const [cancelResponse, setCancelResponse] = useState<string|null>(null)
	const [bookingReference, setBookingReference] = useState<string|null>(null)
	const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
	const {writeTransaction, cancelTransaction} = useRefundableActions()
	const {
		eventDateFormat, bookingData, updateBookingCost, updateCurrency, toggleProtectionSelected
	} = useRefundableContext()

	const clearResponse = () => {
		setApiSuccessResponse({} as WriteTransactionResponse)
		setApiErrorResponse('')
		setCancelResponse(null)
	}

	const handleOptionsChange = (option: string, parse?: (val: any) => any) => (event: any) => {
		let value = event.target.value

		if (parse) {
			value = parse(value)
		}

		onOptionsChange({
			...options,
			[option]: value
		})
	}

	const handleBooleanOptionsChange = (option: string) => (event: any) => {
		onOptionsChange({
			...options,
			[option]: event.target.checked
		})
	}

	const handleBookingCostChange = (event: any) => {
		const value = event.target.value

		if (!value || isNaN(Number(value))) {
			return
		}

		updateBookingCost(parseInt(event.target.value))
	}

	const handleCurrencyChange = (event: any) => {
		updateCurrency(event.target.value)
	}

	const handleProtectionChange = (event: any) => {
		toggleProtectionSelected(event.target.checked)
	}

	const handleApiClick = async () => {
		clearResponse()
		setRequestInProgress(true)
		const eventDate = moment().add(10, 'days')
		const bookingReference = uuid()
		const request: WriteTransactionRequest = {
			eventDate: eventDateFormat ? eventDate.format(eventDateFormat) : eventDate.format(),
			customerFirstName: 'Bat',
			customerLastName: 'Man',
			bookingReference: bookingReference
		}

		try {
			const response = await writeTransaction(request)
			setApiSuccessResponse(response)
			setBookingReference(bookingReference)
		} catch (e: any) {
			const {data: {error}} = e
			setApiErrorResponse(error)
		} finally {
			setRequestInProgress(false)
		}
	}

	const handleCancelClick = async () => {
		if (!bookingReference) {
			return
		}
		clearResponse()
		setRequestInProgress(true)
		try {
			await cancelTransaction(bookingReference.toString())
			setCancelResponse('success')
			setBookingReference(null)
		} catch (e: any) {
			setCancelResponse('failed')
			console.log('cancel failed', e)
		} finally {
			setRequestInProgress(false)
		}
	}

	const handleClearResponseClick = () => clearResponse()

	return (
		<div style={{display: 'inline-flex', flexWrap: 'wrap', width: '100%'}}>
			<InputsWrapper>
				<SubTitle>Some of the props the component is configured with</SubTitle>

				<InputHint>
					<InputContainer>
						<InputLabel>Booking Cost</InputLabel>
						<Input type="number" min={0} value={bookingData.bookingCost} onChange={handleBookingCostChange}/>
					</InputContainer>
					<Hint>
						<span>Passed as a prop into the <code>RefundableContent</code> component (bookingCost: number)</span>
					</Hint>
				</InputHint>

				<InputContainer>
					<InputLabel>Language Code</InputLabel>
					<Select value={options.languageCode} onChange={handleOptionsChange('languageCode')}>
						<Option value="en">English</Option>
						<Option value="fr">French</Option>
						<Option value="es">Spanish</Option>
						<Option value="pt">Portuguese</Option>
					</Select>
				</InputContainer>

				<InputContainer>
					<InputLabel>Currency Code</InputLabel>
					<Input defaultValue={bookingData.currencyCode} onBlur={handleCurrencyChange}/>
				</InputContainer>

				<InputContainer>
					<InputLabel>Premium</InputLabel>
					<Input
						type="number"
						min={0}
						value={options.premium}
						onChange={handleOptionsChange('premium', parseInt)}
					/>
				</InputContainer>

				<InputContainer>
					<InputLabel>Event Date Format</InputLabel>
					<Input value={options.eventDateFormat} onChange={handleOptionsChange('eventDateFormat')}/>
				</InputContainer>

				<InputContainer>
					<InputLabel>Label Text</InputLabel>
					<Select value={options.labelText} onChange={handleOptionsChange('labelText')}>
						<Option value="Default">Default</Option>
						<Option value="Enhance">Enhance</Option>
						<Option value="Upgrade">Upgrade</Option>
						<Option value="Question">Question</Option>
					</Select>
				</InputContainer>

				<InputContainer>
					<InputLabel>Vertical</InputLabel>
					<Select value={options.vertical} onChange={handleOptionsChange('vertical')}>
						<Option value="Default">Default</Option>
						<Option value="Transport">Transport</Option>
						<Option value="Accommodation">Accommodation</Option>
						<Option value="Sport">Sport</Option>
						<Option value="Tours">Tours</Option>
						<Option value="VenuesEvents">VenuesEvents</Option>
						<Option value="MassParticipation">MassParticipation</Option>
					</Select>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="white-labelled" cursor="pointer">White Labelled</InputLabel>
					<Input
						id="white-labelled"
						type="checkbox"
						checked={options.whiteLabelled}
						onChange={handleBooleanOptionsChange('whiteLabelled')}/>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="display-trust-pilot" cursor="pointer">Display Trust Pilot</InputLabel>
					<Input
						id="display-trust-pilot"
						type="checkbox"
						checked={options.displayTrustPilot}
						onChange={handleBooleanOptionsChange('displayTrustPilot')}/>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="display-refund-text" cursor="pointer">Display Refund Text</InputLabel>
					<Input
						id="display-refund-text"
						type="checkbox"
						checked={options.displayRefundText}
						onChange={handleBooleanOptionsChange('displayRefundText')}/>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="non-refund-label-click" cursor="pointer">Allow Non Refundable Label Click</InputLabel>
					<Input
						id="non-refund-label-click"
						type="checkbox"
						checked={options.allowNonRefundableLabelClick}
						onChange={handleBooleanOptionsChange('allowNonRefundableLabelClick')}/>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="compact-click" cursor="pointer">Compact</InputLabel>
					<Input
						id="compact-click"
						type="checkbox"
						checked={options.compact}
						onChange={handleBooleanOptionsChange('compact')}/>
				</InputContainer>

				<InputContainer isCheckbox>
					<InputLabel htmlFor="protection-click" cursor="pointer">Protection Selected</InputLabel>
					<Input
						id="protection-click"
						type="checkbox"
						checked={bookingData.protectionSelected === true}
						onChange={handleProtectionChange}/>
				</InputContainer>
			</InputsWrapper>

			<EventArgsWrapper>
				<SubTitle>The args passed to the protection change event</SubTitle>

				<EventArg>
					<strong>Protection Selected:</strong>
					<span>{changeEventArgs.protectionSelected?.toString() ?? 'null'}</span>
				</EventArg>
				<EventArg>
					<strong>Protection Value:</strong>
					<span>{changeEventArgs.protectionValue}</span>
				</EventArg>
				<EventArg>
					<strong>Booking Cost:</strong>
					<span>{changeEventArgs.bookingCost}</span>
				</EventArg>
				<EventArg>
					<strong>Total Value:</strong>
					<span>{changeEventArgs.totalValue}</span>
				</EventArg>
				<EventArg>
					<strong>Formatted Protection Value:</strong>
					<span>{changeEventArgs.formattedProtectionValue}</span>
				</EventArg>
				<EventArg>
					<strong>Formatted Booking Cost:</strong>
					<span>{changeEventArgs.formattedBookingCost}</span>
				</EventArg>
				<EventArg>
					<strong>Formatted Total Value:</strong>
					<span>{changeEventArgs.formattedTotalValue}</span>
				</EventArg>
			</EventArgsWrapper>

			<ButtonWrapper>
				<Button onClick={handleApiClick} disabled={requestInProgress}>Write Transaction</Button>
				<Button onClick={handleClearResponseClick} disabled={requestInProgress}>Clear Response</Button>
				<Button onClick={handleCancelClick} disabled={!bookingReference || requestInProgress}>Cancel Transaction</Button>
			</ButtonWrapper>

			<ResponseWrapper>
				{
					bookingReference && (
						<div>
							<p><strong>Booking Reference:</strong> {bookingReference}</p>
						</div>
					)
				}
				{
					cancelResponse && (
						<div>
							<p><strong>Cancel Response:</strong> {cancelResponse}</p>
						</div>
					)
				}
				{
					Object.keys(apiSuccessResponse).length > 0 && (
						<div>
							<h4>Request successful. The key/value pairs below are returned</h4>
							<p><strong>refundableLink</strong></p>
							<p>{apiSuccessResponse.refundableLink}</p>
							<p><strong>refundableConfirmationHtml</strong></p>
							<InnerHtml html={apiSuccessResponse.refundableConfirmationHtml}/>
						</div>
					)
				}
				{
					apiErrorResponse && (
						<ErrorResponse>{apiErrorResponse}</ErrorResponse>
					)
				}
			</ResponseWrapper>
		</div>
	)
}

export default TestForm
